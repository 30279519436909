const parseError = (error) => {
  let message = "";
  // console.log("error response", error.response);
  // console.log("error request", error.request);
  // console.log("error message", error.message);

  if (error.response) {
    message = error.response.data ?? error.response.data.message;
  } else if (error.request) {
    message = "Network Error!";
  } else {
    message = error.message;
  }
  return message;
};

export default parseError;
