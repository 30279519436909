import React from "react";
import Banner from "../components/home/banner";
import AOS from "aos";
import About from "../components/home/about";
import Counter from "../components/home/counter";
import Report from "../components/home/report";
import Services from "../components/home/services";
import Breaker from "../components/home/breaker";
import Products from "../components/home/products";
import Testimonials from "../components/home/testimonials";
import Partners from "../components/home/partners";
import { dealerDescription } from "../components/content/text";
import Becomedealer from "../components/home/becomeDealer";
import FashionSwiper from "../components/home/fashionSwiper";
import CookieConsent from "react-cookie-consent";
import Slider from "../components/home/slider";
const Home2 = () => {
  AOS.init({
    duration: 1200,
  });

  return (
    <div>
      <Banner />
      <About />
      <Counter />
      <Report
        title="Ready to"
        spantitle="Report a Repair?"
        tagline="Let's team up to turn this setback into a comeback!"
        downTagLine="Effortless Fixes, Trouble Free Updates!"
        btnTxt="FILE A REPAIR"
        para="Gear up for a seamless repair journey with Extended Trust! If you're covered by our vehicle protection plan, swiftly complete the repair report form. Our team is ready to orchestrate your car's speedy comeback because we believe in hassle free adventures on the road!"
      />
      <Services customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" />
      <Breaker />
      <Products />
      <Testimonials />
      <div className="mt-5 pt-5">
        <FashionSwiper />
      </div>
      <Partners />
      <Becomedealer dealerpara={dealerDescription} page={"/become-a-dealer"} />
      {/* <CookieConsent
        className="myConsent "
        location="bottom"
        buttonText="ACCEPT ALL"
        declineButtonText="REJECT"
        cookieName="SupervetCookie"
        onAccept={(acceptedByScrolling) => {
          if (acceptedByScrolling) {
            // triggered if user scrolls past threshold
            // alert("Accept was triggered by user scrolling");
          } else {
            // alert("Accept was triggered by clicking the Accept button");
          }
        }}
        enableDeclineButton
        onDecline={(test) => {
          console.log("userDenied cookies");
        }}
        style={{
          background: "#4A6543",
          fontFamily: "Poppins",
          fontSize: "16px",
        }}
        buttonStyle={{
          backgroundColor: "#15c215",
          padding: "10px 20px",
          fontSize: "15px",
          fontWeight: 700,
          fontFamily: "Poppins",
          color: "white",
          borderRadius: "10px",
        }}
        declineButtonStyle={{
          borderRadius: "10px",

          padding: "10px 20px",
          backgroundColor: "white",
          fontSize: "15px",
          fontWeight: 700,
          fontFamily: "Poppins",
          color: "black",
        }}
        expires={150}
      >
        Extending our services and providing you with a smoother web experience
        while using our website, we may collect cookies. Cookies used by us are
        to improve your involvement with Extended Trust. Keeping due privacy and
        security measures, we deal with third party services and tools. This may
        involve the use of your information to better assist you in your
        interaction with us. While it is recommended to accept the cookie
        settings as is, we ensure your right to retain any optional data you do
        not want to share. You may choose to ignore these settings on the go.
        For further details, you may view our{" "}
        <a href="/privacy-policy" style={{ textDecoration: "underline" }}>
          Privacy Policy
        </a>
        .
      </CookieConsent> */}
    </div>
  );
};

export default Home2;
