import React from "react";

const Counter = () => {
  return (
    <div className="ltn__counterup-area section-bg-1 pt-120 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-6 align-self-center">
            <div className="ltn__counterup-item text-color-white---">
              <div className="counter-icon">
                <i className="fas fa-star" />
              </div>
              <h1>
                <span className="counter">4.8</span>
                <span className="counterUp-icon">+</span>{" "}
              </h1>
              <h6>GOOGLE RATINGS</h6>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 align-self-center">
            <div className="ltn__counterup-item text-color-white---">
              <div className="counter-icon">
                <i className="fas fa-hand-holding-usd" />
              </div>
              <h1>
                <span className="counter">40</span>
                <span className="counterUp-letter">M</span>
                <span className="counterUp-icon">+</span>{" "}
              </h1>
              <h6>PAID IN CLAIMS</h6>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 align-self-center">
            <div className="ltn__counterup-item text-color-white---">
              <div className="counter-icon">
                <i className="fas fa-clock" />
              </div>
              <h1>
                <span className="counter">6 {""}</span>
                <span className="counterUp-icon">Minutes</span>{" "}
              </h1>
              <h6>AVERAGE APPROVAL TIME</h6>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 align-self-center">
            <div className="ltn__counterup-item text-color-white---">
              <div className="counter-icon">
                <i className="fas fa-users" />
              </div>
              <h1>
                <span className="counter">200,000</span>
                <span className="counterUp-icon">+</span>{" "}
              </h1>
              <h6>CURRENT CUSTOMERS</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
