import React from "react";

import { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

import "./faqs.css";
import Page_header from "../page-header";

const Faqs = () => {
  const faqs = [
    {
      question: "What does Extended Trust vehicle protection cover?",
      answer:
        "Extended Trust covers a range of issues, including mechanical breakdowns, electrical failures, and even some wear-and-tear components, ensuring comprehensive protection for the car.",
    },
    {
      question: "Can I schedule repairs at a time convenient for me?",
      answer: "Yes, you can schedule the repair with Extended Trust.",
    },
    {
      question:
        "How does Extended Trust handle repairs for specialty or luxury vehicles?",
      answer:
        "If you own a specialty or luxury vehicle,  Extended Trust tailors its services to ensure specialized and high-quality repairs for such vehicles.",
    },
    {
      question: "Are there any coverage limitations for older vehicles?",
      answer:
        "Yes, coverage limitations may apply to older vehicles according to the policy of Extended trust.",
    },
    {
      question:
        "What should I do if I encounter issues with the completed repair?",
      answer:
        "If you experience any issues post-repair, contact Extended Trust immediately.  We will guide you on the necessary steps to address and resolve the situation.",
    },
    {
      question: "Can I cancel my coverage at any time?",
      answer:
        "Extended Trust policies typically allow for cancellations, but terms and conditions are applied.",
    },
    {
      question: "Is Extended Trust available for all types of vehicles?",
      answer:
        "Extended Trust provides coverage for various types of vehicles, including cars, trucks, and SUVs, offering tailored solutions for different automotive needs.",
    },
    {
      question: "Can I choose where to get my car repaired?",
      answer:
        "Yes, Extended Trust allows you to choose from a network of authorized repair facilities.",
    },
    {
      question:
        "Is there an emergency repair service available for critical issues?",
      answer:
        "Yes, Extended Trust offers emergency repair services for urgent situations.",
    },
    {
      question:
        "What information should I have ready when reporting a repair issue?",
      answer:
        "Have your policy details, vehicle information, and a description of the issue ready when reporting a repair. This streamlines the process and helps expedite your request.",
    },
    {
      question:
        "Can I get help with service-related advice from Extended Trust?",
      answer: "Yes, Extended Trust provides advice on maintenance and care.",
    },
    {
      question:
        "Does Extended Trust offer services for electric or hybrid vehicles?",
      answer: "Yes, it provides services for electric and hybrid vehicles.",
    },
    {
      question:
        "Are there any discounts for bundling multiple vehicles under Extended Trust coverage?",
      answer: "Yes, discounts may be available for bundling multiple vehicles.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div>
      <Page_header
        headertitle="FAQs"
        imgsrc="/assets/img/selfmade/Images/faqs.png"
      />
      <div className="faqs-container">
        <h1>
          Frequently Asked <span>Questions</span>
        </h1>
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {faq.question}
              {openIndex === index ? <FaMinus /> : <FaPlus />}
            </div>
            <div className={`faq-answer ${openIndex === index ? "show" : ""}`}>
              <div className="py-3">{faq.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faqs;
