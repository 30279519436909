import React from "react";

const About = () => {
  return (
    <div className="ltn__about-us-area pt-90 pb-90 about2-sec">
      <div className="pr-7">
        <div className="row">
          <div className="col-lg-6 align-self-center about-left">
            <div className="about-us-img-wrap about-img-left">
              <video
                autoPlay
                muted
                loop
                className="about1-video about-us-video"
              >
                <source
                  src="/assets/img/selfmade/videos/about.mp4"
                  type="video/mp4"
                />
              </video>
              <img
                src="/assets/img//home-page-video-pic.jpg"
                className="about-us-mobile-image"
                alt="About Us"
                // style={{ display: "none" }}
              />
            </div>
          </div>
          <div className="col-lg-6 align-self-center about-right ">
            <div className="about-us-info-wrap">
              <div className="section-title-area ltn__section-title-2--- mb-20">
                <h6 className="section-subtitle section-subtitle-2 ">
                  About Us
                </h6>
                <h1
                  className="section-title about1-title"
                  style={{ lineHeight: "100%" }}
                >
                  Explore Canada's Top Rated{" "}
                  <span>Vehicle Protection Plans</span>
                </h1>
                <p>
                  Embrace freedom on the open road, comforted by our dedication
                  to your vehicle's safety and intelligence.
                </p>
              </div>
              {/* <ul className="ltn__list-item-half clearfix">
                <li>
                  <i className="fas fa-shield-alt " />
                  Smartly Shielded
                </li>
                <li>
                  <i className="fas fa-car" />
                  Safe Travels
                </li>
                <li>
                  <i className="fas fa-check-circle " />
                  Protected Rides
                </li>
                <li>
                  <i className="fas fa-lock " />
                  Secure Journeys
                </li>
              </ul> */}
              <div className="ltn__callout mt-30">
                <p className="text-justify">
                  Extended Trust delivers unrivaled vehicle protection in
                  Canada, shielding your car from life's mishaps. Our
                  comprehensive programs cover worry free wear care, ensuring
                  your vehicle stays in peak condition. Choose Extended Trust
                  for the optimal solution to safeguard your car, preserving its
                  performance and appearance. We specialize in providing peace
                  of mind through top tier vehicle protection programs tailored
                  for the Canadian market.
                </p>
              </div>
              <div className="btn-wrapper animated go-top">
                <a href="#products" className="theme-btn-1 btn btn-effect-1">
                  OUR PRODUCTS
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container">
        </div> */}
    </div>
  );
};

export default About;
