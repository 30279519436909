import React from "react";
import "./homeproducts.css";
const Products = () => {
  return (
    <>
      <section className="h-products" id="products">
        <div className="section-title-area ltn__section-title-2--- text-center">
          <h6 className="section-subtitle section-subtitle-2 ">Our Products</h6>
          <h1 className="section-title">
            Extended Trust <span> Products</span>
          </h1>
        </div>
        <div className="products-cards">
          <a href="/products" class="flip-card" tabIndex="0">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <span className="category-icon">
                  <i className="fas fa-oil-can" />
                </span>
                <h5 style={{ maxWidth: "210px" }}>
                  {" "}
                  {/* Path to Worry Free Driving Program */}
                  OIL CHANGE PROGRAM
                </h5>
              </div>
              <div class="flip-card-back ">
                <p>
                  Worry free driving with our oil change program. Comprehensive
                  five year plan with the option to extend for a lifetime.
                  Convenience of regular oil changes, ensuring an engine's
                  optimal performance. Extend the life of your vehicle with our
                  hassle free and cost effective program.
                </p>
              </div>
            </div>
            <div className="category-btn">
              <i class="fa fa-arrow-right" style={{ color: "white" }}></i>
            </div>
          </a>
          <a href="/products" class="flip-card" tabIndex="1">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <span className="category-icon">
                  <i className="fas fa-hands-helping" />
                </span>
                <h5 style={{ maxWidth: "210px" }}>Trust Care</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  Uncover the power of knowledge with "Trust Care". Our
                  diagnostic coverage goes beyond the surface, providing a
                  thorough analysis of your vehicle's health.
                </p>
              </div>
            </div>
            <div className="category-btn">
              <i class="fa fa-arrow-right" style={{ color: "white" }}></i>
            </div>
          </a>
          <a href="/products" class="flip-card" tabIndex="2">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <span className="category-icon">
                  <i className="fas fa-shield-alt" />
                </span>{" "}
                <h5 style={{ maxWidth: "210px" }}>Trust Shield</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  Safeguard your view of the road with "Trust Shield". This
                  program covers windshield crack repairs, window replacements,
                  and even sunroof services. Drive fearlessly, knowing that your
                  vehicle's glass is protected. Trust Shield ensures clarity,
                  safety, and peace of mind for every journey.
                </p>
              </div>
            </div>
            <div className="category-btn">
              <i class="fa fa-arrow-right" style={{ color: "white" }}></i>
            </div>
          </a>
          <a href="/products" class="flip-card" tabIndex="3">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <span className="category-icon">
                  <span class="iconify" data-icon="mdi:tire"></span>
                  {/* <i class="fa-solid fa-tire"></i> */}
                </span>{" "}
                <h5 style={{ maxWidth: "210px" }}>Tire and Rim</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  Elevate your driving experience with our "Tire and Rim"
                  program. Enjoy the freedom of the road without worrying about
                  tire damage or rim scuffs. This program offers comprehensive
                  coverage, providing repairs or replacements for your tires and
                  rims. Drive with confidence, knowing that we've got your
                  wheels covered.
                </p>
              </div>
            </div>
            <div className="category-btn">
              <i class="fa fa-arrow-right" style={{ color: "white" }}></i>
            </div>
          </a>
          <a href="/products" class="flip-card" tabIndex="4">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <span className="category-icon">
                  <i className="fas fa-key" />
                </span>{" "}
                <h5 style={{ maxWidth: "210px" }}>Key Fob Replacement</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  Never be stranded again with our "Key Fob Replacement"
                  program. Losing or damaging your key fob can be stressful, but
                  with our coverage, a replacement is just a call away.
                  Experience the convenience of seamless access to your vehicle,
                  ensuring you're always in control.
                </p>
              </div>
            </div>
            <div className="category-btn">
              <i class="fa fa-arrow-right" style={{ color: "white" }}></i>
            </div>
          </a>
          <a href="/products" class="flip-card" tabIndex="5">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <span className="category-icon">
                  <i className="fas fa-car" />
                </span>{" "}
                <h5 style={{ maxWidth: "210px" }}>Brakes and Rotors</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  Prioritize safety with our "Brakes and Rotors" program.
                  Comprehensive coverage for your braking system ensures optimal
                  performance and responsiveness. From brake pad replacements to
                  rotor maintenance, trust us to keep your vehicle braking
                  smoothly, providing you with peace of mind on every journey.
                </p>
              </div>
            </div>
            <div className="category-btn">
              <i class="fa fa-arrow-right" style={{ color: "white" }}></i>
            </div>
          </a>
          <a href="/products" class="flip-card" tabIndex="6">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <span className="category-icon">
                  <i className="fas fa-eye" />
                </span>{" "}
                <h5 style={{ maxWidth: "210px" }}>Appearance Trust</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  Preserve the beauty of your vehicle with our "Appearance
                  Trust" program. Say goodbye to dents, scratches, rock chips,
                  and windshield chips. Our comprehensive coverage ensures that
                  your vehicle stays in pristine condition. Trust us to restore
                  and maintain the appearance of your car, allowing you to drive
                  with confidence and pride.
                </p>
              </div>
            </div>
            <div className="category-btn">
              <i class="fa fa-arrow-right" style={{ color: "white" }}></i>
            </div>
          </a>
          <a href="/products" class="flip-card" tabIndex="7">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <span className="category-icon">
                  <i className="fas fa-microscope" />
                </span>{" "}
                <h5 style={{ maxWidth: "210px" }}>Trust Guard</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  Our Trust Guard ceramic coating products provide an advanced
                  layer of protection for your vehicle's paint and shine,
                  ensuring it stays in showroom condition.
                </p>
              </div>
            </div>
            <div className="category-btn">
              <i class="fa fa-arrow-right" style={{ color: "white" }}></i>
            </div>
          </a>
          <a href="/products" class="flip-card" tabIndex="8">
            <div class="flip-card-inner">
              <div class="flip-card-front">
                <span className="category-icon">
                  <i className="fas fa-lock" />
                </span>{" "}
                <h5 style={{ maxWidth: "210px" }}>Anti-Theft</h5>
              </div>
              <div class="flip-card-back">
                <p>
                  With Anti-Theft, you can enjoy peace of mind knowing that your
                  vehicle is protected and that you have financial support in
                  case of theft.
                </p>
              </div>
            </div>
            <div className="category-btn">
              <i class="fa fa-arrow-right" style={{ color: "white" }}></i>
            </div>
          </a>
        </div>
      </section>
    </>
  );
};

export default Products;
