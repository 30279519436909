import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PartnersSlider = () => {
  const partners = [
    { source: "/assets/img/partners/New folder/2.png" },
    { source: "/assets/img/partners/New folder/1.png" },
    { source: "/assets/img/partners/New folder/3.png" },
    { source: "/assets/img/partners/New folder/4.png" },
    { source: "/assets/img/partners/New folder/5.png" },
    { source: "/assets/img/partners/New folder/6.png" },
    { source: "/assets/img/partners/New folder/7.png" },
    { source: "/assets/img/partners/New folder/8.png" },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    speed: 7000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplaySpeed: 100,
          speed: 5000,
        },
      },
    ],
  };

  return (
    <div className="partners-sec ">
      <h1 className="section-title" style={{ color: "--ltn__heading-color" }}>
        Our <span>Partners</span>
      </h1>
      <Slider {...settings}>
        {partners.map((partner, index) => (
          <div key={index}>
            <img src={partner.source} alt={partner.name} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PartnersSlider;
