import React from "react";
import Page_header from "../components/page-header";
import Aboutdetail from "../components/aboutdetail/aboutdetail";
import {
  aboutDetailDescription,
  dealerDescription,
} from "../components/content/text";
import Chooseus from "../components/chooseus/chooseus";
import PartnersSlider from "../components/partners";
import Aboutgrowing from "../components/aboutgrowing/aboutgrowing";
import Values from "../components/values/values";
import Becomedealer from "../components/becomedealer/becomedealer";

const About = () => {
  return (
    <div>
      <Page_header
        headertitle="About Us"
        imgsrc="/assets/img/selfmade/Images/repair.png"
      />
      <Aboutdetail
        description={aboutDetailDescription}
        heading="About "
        headingspan="Us"
      />
      {/* <Aboutgrowing /> */}

      <Values customClass="pb-90" />
      <Chooseus customClass="pt-90" />
      <PartnersSlider />
      <Becomedealer dealerpara={dealerDescription} />
    </div>
  );
};

export default About;
