import React from "react";
import "./privacypolicy.css";
import Page_header from "../page-header";

const Privacypolicy = () => {
  return (
    <div>
      <Page_header
        headertitle="Privacy Policy"
        subheader="Privacy Policy"
        imgsrc="/assets/img/selfmade/Images/privacy.jpg"
      />
      <div className="privacyInfo-center">
        <h2>
          Extended Trust <span>PRIVACY POLICY</span>
        </h2>
        {/* <p>
          <strong>Type of website:</strong> Service Based
        </p> */}
        <p>
          <strong>Effective date:</strong> November 29th, 2023
        </p>
        <p>
          <strong>
            If you have any questions or concerns about this Privacy Policy or
            our privacy practices, please contact us at:
            <br />
          </strong>
          <a href="mailto:support@extendedtrust.ca" target="_blank">
            support@extendedtrust.ca
          </a>
          <br />
          778-229-8847
          <br />
          200 14964 121A Ave NW Edmonton Alberta
        </p>
        <h4>Introduction</h4>
        <p>
          Extended Trust ("we," "our," or "us") is committed to protecting your
          privacy. This Privacy Policy explains how we collect, use, disclose,
          and safeguard your personal information when you use our website and
          services. By accessing or using our website, you consent to the
          practices described in this Privacy Policy.
        </p>

        <h4>Information We Collect</h4>
        <p>
          We may collect the following types of personal information when you
          interact with us:
        </p>
        <ol>
          <li>
            Contact Information: This includes your name, email address, phone
            number, and mailing address, which we may collect when you
            communicate with us or use our services.
          </li>
          <li>
            Vehicle Information: When you inquire about or purchase our vehicle
            protection programs, we may collect information about your vehicle,
            such as its make, model, and VIN (Vehicle Identification Number).
          </li>
          <li>
            Usage Data: We may collect information about how you interact with
            our website, including your browsing history, pages visited, and
            device information.
          </li>
        </ol>

        <h4>
          How We Use <span>Your Information</span>
        </h4>
        <p>We may use your personal information for the following purposes:</p>
        <ol>
          <li>
            To Provide Services: We use your information to provide and manage
            our vehicle protection programs and services.
          </li>
          <li>
            Communication: We may use your contact information to communicate
            with you, respond to your inquiries, and send you important updates
            and information related to our services.
          </li>
          <li>
            Marketing: With your consent, we may send you marketing
            communications about our products and services.
          </li>
          <li>
            Analytics: We may use your information for analytical purposes to
            improve our website and services.
          </li>
        </ol>

        <h4>
          Disclosure of <span>Your Information</span>
        </h4>

        <p>We may share your personal information with:</p>
        <ol>
          <li>
            <h6>Service Providers :</h6> We may share your information with
            third party service providers who assist us in delivering our
            services.
          </li>
          <li>
            <h6>Legal Requirements :</h6> We may disclose your information to
            comply with legal obligations or respond to lawful requests.
          </li>
        </ol>
        <h4>Your Choices</h4>
        <p>
          You have the following choices regarding your personal information:
        </p>
        <ol>
          <li>
            <h6>Access and Correction :</h6> You can request access to your
            personal information or request corrections to inaccuracies.
          </li>
          <li>
            <h6>Opt-Out :</h6> You can opt-out of receiving marketing
            communications from us by following the instructions provided in our
            communications.
          </li>
        </ol>
        <h4>Security</h4>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure.
        </p>
        <h4>
          Links to <span>Third Party Websites</span>
        </h4>
        <p>
          Our website may contain links to third party websites. We are not
          responsible for the privacy practices or content of these websites.
        </p>
        <h4>Children's Privacy</h4>
        <p>
          Our services are not intended for children under the age of 13. We do
          not knowingly collect personal information from children.
        </p>
        <h4>
          Changes to this <span>Privacy Policy</span>
        </h4>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices. The revised Privacy Policy will be posted on our
          website with the updated effective date.
        </p>
      </div>
    </div>
  );
};

export default Privacypolicy;
