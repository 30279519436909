import React from "react";
import Slider from "./slider";

const FashionSwiper = () => {
  return (
    <>
      <div className="position-relative">
        {/* <iframe
          className=""
          src="https://fashion-slider.vercel.app/"
          style={{ width: "100vw", height: "100vh" }}
        ></iframe> */}
        <Slider />
        <img
          src="/assets/img/selfmade/Images/smoke.png"
          alt=""
          className="smoke-layer"
        />
      </div>
    </>
  );
};

export default FashionSwiper;
