import React from "react";
import "./aboutdetail.css";
const Aboutdetail = ({ description, heading, headingspan }) => {
  return (
    <section className="about-detail">
    <div className="left">
    <h1 className="section-title">
        {heading}
        <span> {headingspan}</span>
      </h1>
      <p>{description}</p>
      <div className="btn-wrapper animated go-top">
          <a href="/contact" className="theme-btn-1 btn btn-effect-1">
           CONTACT US
          </a>
        </div>
    </div>
    <div className="right">
      <img src="../../../assets/img/selfmade/Images/parking.jpg" alt="" />
    </div>
    </section>
  );
};

export default Aboutdetail;
