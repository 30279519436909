import React from "react";
import "./values.css";
const Values = (props) => {
  return (
    <section className={`values-sec ${props.customclass}`}>
      <h1 className="section-title">
        OUR <span>VALUES</span>
      </h1>
      <div className="values-content">
        <div className="ist">
          <div className="icon-side">
            <img src="/assets/img/selfmade/icon/values/reliable.png" alt="" />
          </div>
          <div className="detail-seide">
            <h1>Reliability</h1>
            <p>
              Ensuring steadfast and dependable protection plans for every
              vehicle.
            </p>
          </div>
        </div>
        <div className="ist">
          <div className="icon-side">
            <img src="/assets/img/selfmade/icon/values/trust.png" alt="" />
          </div>
          <div className="detail-seide">
            <h1>Customer Centric</h1>
            <p>
              {" "}
              Placing customers at the forefront, offering personalized service
              and attention.
            </p>
          </div>
        </div>
        <div className="ist">
          <div className="icon-side">
            <img
              src="/assets/img/selfmade/icon/values/APPRECIATIVE.png"
              alt=""
            />
          </div>
          <div className="detail-seide">
            <h1>Innovation</h1>
            <p>
              Continuously evolving protection programs to align with industry
              advancements and technological changes.
            </p>
          </div>
        </div>
        <div className="ist">
          <div className="icon-side">
            <img src="/assets/img/selfmade/icon/values/years.png" alt="" />
          </div>
          <div className="detail-seide">
            <h1>Integrity</h1>
            <p>
              Upholding strong ethical standards in all dealings, fostering
              trust with clients and partners.
            </p>
          </div>
        </div>
        <div className="ist">
          <div className="icon-side">
            <img src="/assets/img/selfmade/icon/values/dependable.png" alt="" />
          </div>
          <div className="detail-seide">
            <h1>Excellence</h1>
            <p>
              Striving for excellence in every aspect; from service delivery to
              client satisfaction to setting industry standards.
            </p>
          </div>
        </div>
        <div className="ist">
          <div className="icon-side">
            <img src="/assets/img/selfmade/icon/values/canadian.png" alt="" />
          </div>
          <div className="detail-seide">
            <h1>Proudly Canadian</h1>
            <p>
              As proud Canadians, we are committed to restoring your vehicle's
              top condition.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Values;
