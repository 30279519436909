import React from "react";

const CopyRight = () => {
  return (
    <div>
      <div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
        <div className="container-fluid ltn__border-top-2">
          <div className="row">
            <div className="col-lg-7 col-12">
              <div className="ltn__copyright-design clearfix ">
                <p>
                  All Rights Reserved Ⓒ 2024 Extended Trust{" "}
                  <span className="current-year" />
                  Powered by{" "}
                  <a
                    target="_blank"
                    href="https://pulsemarketing.ca/"
                    className="pulse-anchor"
                    style={{ textDecoration: "underline" }}
                  >
                    Pulse Marketing
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-12 align-self-center">
              <div className="ltn__copyright-menu text-end">
                <ul className="go-top">
                  <li>
                    <a href="/terms&conditions">Terms &amp; Conditions</a>
                  </li>
                  <li>|</li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyRight;
