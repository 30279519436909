import React from "react";
import { Icon } from "@iconify/react";
import "./ReviewBox.css";

const ReviewBox = ({ review, person, date }) => {
  return (
    <div className="review-box">
      <div className="d-flex">
        <Icon icon="ic:round-star" className="icon-star" />
        <Icon icon="ic:round-star" className="icon-star" />
        <Icon icon="ic:round-star" className="icon-star" />
        <Icon icon="ic:round-star" className="icon-star" />
        <Icon icon="ic:round-star" className="icon-star" />
      </div>
      <div className="review-person-name">
        <p>{review}</p>
        <h5>{person}</h5>
        <p>{date}</p>
      </div>
    </div>
  );
};

export default ReviewBox;
