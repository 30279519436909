import React from "react";
import Page_header from "../components/page-header";
import ContactInfo from "../components/contact/contactInfo/ContactInfo";
import Partners from "../components/home/partners";
import Contactform from "../components/contact/contactform/contactform";

const Contact = () => {
  return (
    <div>
      <Page_header
        headertitle="Contact Us"
        subheader="Contact"
        imgsrc="/assets/img/selfmade/Images/contactuscopy.jpg"
      />
      <ContactInfo />
      <Partners />
      <Contactform />
    </div>
  );
};

export default Contact;
