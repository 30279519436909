import React from 'react';
import CookieConsent from 'react-cookie-consent';

const Banner = () => {
  return (
    <div>
      <div className='ltn__slider-area ltn__slider-4 position-relative ltn__primary-bg hero-sec'>
        <div className='ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active'>
          {/* HTML5 VIDEO */}
          <video
            playsInline
            autoPlay
            muted
            loop
            id='myVideo'
            poster='/assets/img/selfmade/Images/hero.jpg'
            className='d-md-block d-none'
          >
            <source src='/assets/img/selfmade/videos/9.mp4' type='video/mp4' />
          </video>
          <video
            playsInline
            autoPlay
            muted
            loop
            id='myVideo'
            poster='/assets/img/selfmade/Images/hero.jpg'
            className='d-md-none d-block'
          >
            <source
              src='https://s3.us-west-2.amazonaws.com/extendedtrustcdn/9.1.2024.mp4'
              type='video/mp4'
            />
          </video>
          <div
            className='ltn__slide-item ltn__slide-item-2 ltn__slide-item-7 bg-image--- bg-overlay-theme-black-10---'
            data-bs-bg='img/slider/41.jpg'
          >
            <div className='ltn__slide-item-inner text-center'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12 align-self-center'>
                    <div className='slide-item-info'>
                      <div className='slide-item-info-inner '>
                        <h1
                          className='slide-title text-uppercase white-color '
                          data-aos='zoom-in'
                          style={{ position: 'relative', zIndex: '4' }}
                        >
                          Securing Your Vehicle
                        </h1>
                      </div>
                    </div>
                    <div className='banner-bottom'>
                      <div className='banner-line'>
                        <div className='top'>
                          <h6 className='slide-sub-title text-white  '>
                            <span>
                              <i className='fas fa-car' />
                            </span>
                            Vehicle Protection and Security
                          </h6>
                        </div>
                        <div className='bottom'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='banner-layer'></div>
          <img
            src='/assets/img/selfmade/Images/smoke.png'
            alt='...'
            className='smoke-layer'
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
