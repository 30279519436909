import React from "react";
import "./reviews.css";
import { Icon } from "@iconify/react";
import Page_header from "../page-header";
import ReviewBox from "./reviewbox/ReviewBox";

const Reviews = () => {
  return (
    <div>
      <Page_header
        headertitle="Taricks Notes"
        imgsrc="/assets/img/selfmade/Images/reviews-2.png"
      />
      <div className="reviews-container">
        <h1>
          OUR <span>Taricks Notes</span>
        </h1>
        <div className="d-flex justify-content-between">
          <Icon icon="ooui:quotes-ltr" className="icon-qoutes-1" />
          <Icon icon="ooui:quotes-rtl" className="icon-qoutes-2" />
        </div>
        <div className="review-item">
          <div className="review-text-left">
            <ReviewBox
              review="Extended Trust has been a lifesaver for my car! Their vehicle
                    protection plans are unmatched in Canada, and I can't imagine owning a
                    car without their comprehensive coverage."
              person="Emily J"
              date="2023-11-14"
            />
            <ReviewBox
              review="I've been a loyal Extended Trust customer for years, and they have never let me down. Their commitment to vehicle safety and protection is truly commendable."
              person="Michael A"
              date="2023-11-14"
            />
            <ReviewBox
              review="Choosing Extended Trust for my car's protection was one of the best decisions I've made. Their programs are tailor-made for the Canadian market and offer unbeatable peace of mind."
              person="David M"
              date="2023-11-14"
            />
            <ReviewBox
              review="I can't thank Extended Trust enough for preserving my car's performance and appearance. Their vehicle protection plans are a musthave for any Canadian driver"
              person="Lisa C"
              date="2023-11-14"
            />
            <ReviewBox
              review="Extended Trust delivers on its promise of worry-free wear care. My car has never looked better, thanks to their top-tier protection programs."
              person="Brian W"
              date="2023-11-15"
            />
            <ReviewBox
              review="As a proud Extended Trust customer, I can say that their vehicle protection plans are worth every penny. I drive with confidence knowing my car is in safe hands."
              person="Jessica A"
              date="2023-11-15"
            />
            <ReviewBox
              review="Extended Trust goes above and beyond to shield your car from life's mishaps. Their dedication to vehicle safety is unmatched in Canada."
              person="Kevin T"
              date="2023-11-17"
            />
            <ReviewBox
              review="I've recommended Extended Trust to all my friends and family. Their vehicle protection plans are a game-changer for car owners in Canada."
              person="Laura S"
              date="2023-11-17"
            />
            <ReviewBox
              review="Extended Trust is the go-to choice for anyone looking to protect their car in Canada. Their programs are comprehensive, and their service is impeccable."
              person="Daniel M"
              date="2023-11-18"
            />
            <ReviewBox
              review="I'm impressed with Extended Trust's commitment to my car's safety and intelligence. Their protection plans have exceeded my expectations."
              person="Olivia W"
              date="2023-11-18"
            />
          </div>
          <div className="review-text-right">
            <ReviewBox
              review="Extended Trust has made owning a car in Canada stress-free. I can't imagine driving without their excellent vehicle protection."
              person=" Robert B"
              date="2023-11-18"
            />
            <ReviewBox
              review="My experience with Extended Trust has been nothing short of outstanding. Their team's attention to detail and dedication to customer satisfaction are truly remarkable."
              person="Sarah A"
              date="2023-11-19"
            />
            <ReviewBox
              review="Extended Trust's vehicle protection plans are a lifesaver for anyone who values their car. I sleep better at night knowing my vehicle is in their capable hands."
              person="Mark T"
              date="2023-11-19"
            />
            <ReviewBox
              review="I've been a satisfied Extended Trust customer for over a decade. Their consistent quality and commitment to car protection make them the best in the business."
              person="Jennifer L"
              date="2023-11-20"
            />
            <ReviewBox
              review="Extended Trust has earned my trust with their exceptional service. Their vehicle protection plans have saved me from unexpected expenses and headaches."
              person="Christopher C"
              date="2023-11-22"
            />
            <ReviewBox
              review="Choosing Extended Trust was a no-brainer for me. Their vehicle protection plans are comprehensive, affordable, and provide the peace of mind every car owner needs."
              person="Patricia M"
              date="2023-11-21"
            />
            <ReviewBox
              review="Extended Trust's dedication to keeping my car in top shape has been a game-changer. Their attention to detail and commitment to excellence set them apart."
              person="Thomas W"
              date="2023-11-22"
            />
            <ReviewBox
              review="I've recommended Extended Trust to all my colleagues, and they have all thanked me for introducing them to such a reliable and trustworthy car protection provider"
              person="Rebecca A"
              date="2023-11-26"
            />
            <ReviewBox
              review="Extended Trust is the gold standard in vehicle protection. Their plans are flexible, and their customer service is top-notch. I wouldn't trust anyone else with my car"
              person="Matthew T"
              date="2023-11-27"
            />
            <ReviewBox
              review="I'm grateful to Extended Trust for their unwavering support in keeping my car safe and reliable. Their dedication to vehicle protection is unmatched, and I'm a loyal customer for life."
              person="Elizabeth C"
              date="2023-11-29"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
