import React from "react";
import Aboutdetail from "../components/aboutdetail/aboutdetail";
import Testimonials from "../components/home/testimonials";
import Chooseus from "../components/chooseus/chooseus";
import PartnersSlider from "../components/partners";
import Becomedealer from "../components/becomedealer/becomedealer";
import { dealerDescription, dealershort } from "../components/content/text.js";
import Page_header from "../components/page-header";
const Becomedealerpage = () => {
  return (
    <div>
      <Page_header
        headertitle="Become a Dealer"
        imgsrc="/assets/img/selfmade/Images/becomedealer2.jpg"
      />
      <Aboutdetail
        description={dealerDescription}
        heading="Become a "
        headingspan="Dealer"
      />
      <Chooseus customClass="pb-90" />
      <Testimonials />

      <PartnersSlider />
      <Becomedealer
        dealerpara={dealershort}
        page={"https://dashboard.extendedtrust.ca/"}
      />
    </div>
  );
};

export default Becomedealerpage;
