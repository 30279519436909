import React, { useEffect, useState } from "react";
import "./repairs.css";
import axios from "axios";
import Page_header from "../page-header";
import Repairabout from "./repairabout";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Icon } from "@iconify/react";
import parseError from "../../utils/ErrorParse";
import Loader from "../Loader/Loader";
import { NumericFormat } from "react-number-format";

const Repairs = () => {
  const [repairData, setRepairData] = useState({
    name: "",
    email: "",
    phone: "",
    agreement: "",
    image: "",
    reportDescription: "",
    odometerReading: 0,
  });

  const [submitLoader, setSubmitLoader] = useState(false);

  const apiBaseUrl = "https://extended-trust-nodejs.onrender.com/";
  // const apiBaseUrl = "http://localhost:3000/";

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    // console.log("const { name, value, files } = e.target;", name, value, files);

    setRepairData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  useEffect(() => {
    if (repairData.image) {
      const fileNameLabel = document.querySelector(".File-label");
      // console.log("fileNameLabel", fileNameLabel);
      if (fileNameLabel) {
        fileNameLabel.textContent = `Selected File: ${repairData.image.name}`;
      }
    }
  }, [repairData.image]);

  // const convertToBase64 = (e) => {
  //   const file = e.target.files[0];
  //   if (!file) {
  //     return; // No file selected
  //   }

  //   const reader = new FileReader();
  //   reader.onload = (event) => handleImageLoad(event, file.name);
  //   reader.readAsDataURL(file);
  // };

  // const handleImageLoad = (e, fileName) => {
  //   const base64Image = e.target.result;
  //   console.log(base64Image);

  //   // Check if the image is different before updating the state
  //   if (base64Image !== repairData.image) {
  //     setRepairData((prev) => ({
  //       ...prev,
  //       image: base64Image,
  //     }));

  //     // Update label text with file name
  //     const fileNameLabel = document.querySelector(".File-label");
  //     if (fileNameLabel) {
  //       fileNameLabel.textContent = `Selected File: ${fileName}`;
  //     }
  //   }
  // };

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    agreement: "",
    image: "",
    reportDescription: "",
    odometerReading: "",
  });

  const validationForm = () => {
    let errorObj = {
      name: false,
      email: false,
      phone: false,
      agreement: false,
      image: false,
      reportDescription: false,
      odometerReading: false,
    };

    if (repairData.name === "" || repairData === undefined) {
      setFormErrors((prevData) => ({
        ...prevData,
        name: true,
      }));

      errorObj.name = true;
    } else if (repairData.email === "") {
      setFormErrors((prevData) => ({
        ...prevData,
        email: true,
      }));
      errorObj.email = true;
    } else if (repairData.phone === "") {
      setFormErrors((prevData) => ({
        ...prevData,
        phone: true,
      }));
      errorObj.phone = true;
    } else if (repairData.agreement === "") {
      setFormErrors((prevData) => ({
        ...prevData,
        agreement: true,
      }));
      errorObj.agreement = true;
    } else if (repairData.image === "") {
      setFormErrors((prevData) => ({
        ...prevData,
        image: true,
      }));
      errorObj.image = true;
    } else if (repairData.reportDescription === "") {
      setFormErrors((prevData) => ({
        ...prevData,
        reportDescription: true,
      }));
      errorObj.reportDescription = true;
    } else if (
      repairData.odometerReading === "" ||
      repairData.odometerReading === undefined ||
      repairData.odometerReading === 0
    ) {
      setFormErrors((prevData) => ({
        ...prevData,
        odometerReading: true,
      }));
      errorObj.odometerReading = true;
    }

    return errorObj;
  };

  const handleSubmit = async (e) => {
    if (submitLoader) return;
    const valid = validationForm();

    if (
      valid.name ||
      valid.email ||
      valid.phone ||
      valid.image ||
      valid.agreement ||
      valid.reportDescription ||
      valid.odometerReading
    ) {
      console.log("test");
    } else {
      e.preventDefault();
      // const ClaimData = new repairData(e.target);
      try {
        // console.log(repairData);
        let formData = new FormData();
        formData.append("name", repairData.name);
        formData.append("email", repairData.email);
        formData.append("phoneNumber", repairData.phone);
        formData.append("agreementNumber", repairData.agreement);
        formData.append("image", repairData.image);
        formData.append("reportDescription", repairData.reportDescription);
        formData.append("odometerReading", repairData.odometerReading);

        setSubmitLoader(true);

        const response = await axios.post(
          `${apiBaseUrl}dealer/repair`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Swal.fire({
        //   title: "Thank You!",
        //   text: "Your report has been submitted successfully.",
        //   icon: "success",
        //   confirmButtonColor: "#4A6543",
        //   customClass: {
        //     icon: "custom-success-icon-class",
        //   },
        // });
        // toast.success("Report Has Been Sent!!");

        const result = await response.data;
        console.log(result);
        setShowmodal(true);
        setRepairData({
          name: "",
          email: "",
          phone: "",
          agreement: "",
          image: "",
          reportDescription: "",
        });
        const fileNameLabel = document.querySelector(".File-label");
        if (fileNameLabel) {
          fileNameLabel.textContent = "";
        }
        setSubmitLoader(false);
      } catch (error) {
        setSubmitLoader(false);
        console.log(error);
        const errorMessage = parseError(error);
        toast.error(errorMessage?.message ?? "Something went wrong!");
      }
    }
  };

  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [showmodal, setShowmodal] = useState(false);

  // const handlePhoneNumberChange = (e) => {
  //   const inputValue = e.target.value;
  //   const formattedValue = inputValue.startsWith("+1") ? inputValue : `+1 ${inputValue}`;

  //   // Phone number pattern for US and Canadian numbers
  //   const phonePattern = /^(\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/;
  //   const isValid = phonePattern.test(formattedValue);

  //   if (isValid || inputValue === "") {
  //     setRepairData({ ...repairData, phone: formattedValue });
  //   }

  //   setIsValidPhoneNumber(isValid);
  // };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div>
      <Modal show={showmodal} className="confirmmodal">
        <Modal.Body>
          <Icon
            icon="mdi:tick-circle"
            width="60"
            height="60"
            style={{ color: "#4A6543" }}
          />
          <h4>Thank You!</h4>
          <p>Your report has been submitted successfully.</p>
          <button onClick={() => setShowmodal(false)}>OK</button>
        </Modal.Body>
      </Modal>
      <Page_header
        headertitle="Repairs"
        subheader="Repairs"
        imgsrc="/assets/img/selfmade/Images/about-back.jpg"
      />
      <Repairabout />
      <div className="repairsForm-container">
        <h2 className="text-center">
          PERSONAL & AGREEMENT <br />
          <span>INFORMATION</span>
        </h2>
        <form className="contact-form" name="contact-form" data-recaptcha="">
          <div class="form-field mt-4">
            <label class="label" for="msg-name">
              Full Name <span class="required">*</span>
            </label>
            <input
              type="text"
              name="name"
              class="input jsvalidate"
              id="msg-name"
              value={repairData.name}
              onChange={handleInputChange}
              required
            />
            {formErrors.name && (
              <p className="ErrorMessage">Name is required</p>
            )}
          </div>
          <div class="form-field">
            <label class="label" for="msg-email">
              Email Address <span class="required">*</span>
            </label>
            <input
              type="email"
              name="email"
              id="msg-email"
              class="input jsvalidate"
              value={repairData.email}
              onChange={handleInputChange}
              required
            />
            {formErrors.email && (
              <p className="ErrorMessage">Email is required</p>
            )}
          </div>

          <div class="form-field">
            <label class="label" for="msg-phone">
              Phone Number <span class="required">*</span>
            </label>
            {/* <input
              type="number"
              name="phone"
              id="msg-phone"
              class="input jsvalidate "
              value={repairData.phone}
              onChange={handleInputChange}
              required
            /> */}
            <input
              maxLength={13}
              type="tel" // Change type to "tel" to allow numeric input
              name="phone"
              id="msg-phone"
              className={`input jsvalidate ${
                !isValidPhoneNumber ? "invalid" : ""
              }`}
              value={repairData.phone}
              onChange={(event) => {
                const { name, value } = event.target;
                const formattedValue = value.startsWith("+1")
                  ? value
                  : `+1 ${value}`;

                // If the input field is for phone, validate the phone number
                if (name === "phone") {
                  // handlePhoneNumberChange(formattedValue);

                  // Additional pattern test for Canadian phone number (for example purposes)
                  const phonePattern =
                    /^(\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/;
                  const isValidPhoneNumber = phonePattern.test(formattedValue);

                  // Update the state for phone number validation
                  setIsValidPhoneNumber(isValidPhoneNumber);
                }

                setRepairData((prev) => ({
                  ...prev,
                  [name]: formattedValue,
                }));
              }}
              required
              // pattern="^\+1\d{10}$"// Pattern to enforce the desired format
            />
            {formErrors.phone && (
              <p className="ErrorMessage">PhoneNumber is required</p>
            )}
          </div>
          <div class="form-field">
            <label class="label" for="msg-agreement">
              Contract Number<span class="required">*</span>
            </label>
            <input
              type="text"
              name="agreement"
              id="msg-agreement"
              class="input jsvalidate"
              value={repairData.agreement?.toUpperCase()}
              onChange={handleInputChange}
              required
            />
            {formErrors.agreement && (
              <p className="ErrorMessage">Contract Number is required</p>
            )}
          </div>
          <div class="form-field">
            <label class="label" for="msg-agreement">
              Odometer Reading<span class="required">*</span>
            </label>
            <NumericFormat
              allowNegative={false}
              required={true}
              placeholder="Odometer Raading"
              id="numericInput"
              min={1}
              maxLength={"15"}
              className={classNames("text numericInput")}
              value={
                repairData?.odometerReading ? repairData?.odometerReading : null
              }
              valueIsNumericString={true}
              decimalScale={2}
              allowLeadingZeros={false}
              onValueChange={(e) => {
                const { value } = e;
                handleInputChange({
                  target: { name: "odometerReading", value: value },
                });
              }}
            />
            {formErrors.odometerReading && (
              <p className="ErrorMessage">Odometer Reading is required</p>
            )}
          </div>
          <div class="form-field">
            <label class="label" for="msg-file">
              Image/Video <span class="required">*</span>
            </label>
            <input
              type="file"
              accept="image/*"
              name="image"
              id="msg-file"
              class="input jsvalidate d-none"
              onChange={handleInputChange}
              // onChange={(e) => convertToBase64(e)}
              required
            />

            <label class="File-label" for="msg-file">
              Choose File
            </label>
            {formErrors.image && (
              <p className="ErrorMessage">please Upload Image</p>
            )}
          </div>
          <div class="form-field">
            <label class="label" for="msg-comments">
              Report Description <span class="required">*</span>
            </label>
            <textarea
              name="reportDescription"
              id="msg-comments"
              class="textarea jsvalidate"
              value={repairData.reportDescription}
              onChange={handleInputChange}
              required
            ></textarea>
            {formErrors.reportDescription && (
              <p className="ErrorMessage"> Report Description is required</p>
            )}
          </div>
          <div class="form-buttons right">
            <button
              disabled={submitLoader}
              type="submit"
              name="submitform"
              class={`button submit theme-btn-1 btn btn-effect-1 d-flex justify-content-between align-items-center`}
              style={
                submitLoader ? { pointerEvents: "none", opacity: "0.5" } : {}
              }
              onClick={handleSubmit}
            >
              <span>SEND YOUR REPORT</span>
              {submitLoader && (
                <Loader cssClass={"text-black"} isSmallView={true} />
              )}
            </button>
          </div>
          <div
            class="recaptcha-modal hidden-modal"
            title="Verify You&rsquo;re Not a Robot"
          >
            <div class="recaptcha-wrapper">
              <div
                id="recaptcha-contact-form"
                class="g-recaptcha"
                data-sitekey="6Ld3_UcUAAAAAERxamTGAnRHI-VXvmJJCkfG2KWg"
              ></div>
            </div>
          </div>
          <input
            type="hidden"
            name="xid"
            value="d7e90aac166553141e80b3e173c30b6a"
          />
          <input type="hidden" name="g-recaptcha-response" />
        </form>
      </div>
    </div>
  );
};

export default Repairs;
