import React from "react";
import "./team.css";

import Page_header from "../page-header";
import TeamCard from "./TeamCard";

const Team = () => {
  return (
    <div>
      <Page_header
        headertitle="Meet the Team"
        imgsrc="/assets/img/selfmade/Images/team.jpg"
      />
      <div className="meettheteam-container">
        <div className="meettheteam-text-container">
          <h2>
            Empowering Success: <span>Meet the Team Behind Extended Trust</span>
          </h2>
          <p>
            At Extended Trust, our dedicated team is the driving force
            propelling our company to new heights. We firmly believe that a
            strong and united team is the bedrock of any thriving organization.
          </p>
          <p>
            We cultivate an environment where teamwork thrives, where ideas flow
            freely, and where every team member's perspective is valued. We
            recognize that the collective wisdom and diverse skills of our team
            empower us to overcome intricate challenges and achieve remarkable
            outcomes.
          </p>
          <p>
            Above all, our team shares a common purpose and unwavering
            commitment to Extended Trust's mission and values. Our passion for
            what we do is palpable, translating into the exceptional quality of
            our work and the unmatched level of service we offer to our dealer
            partners and clients.
          </p>
        </div>

        <TeamCard />
      </div>
    </div>
  );
};

export default Team;
