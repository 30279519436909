import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";

const Splash = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const timeoutId = setTimeout(() => {}, countdown * 500);

    const countdownIntervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
      clearInterval(countdownIntervalId);
    };
  }, [navigate, countdown]);
  const [topPosition, setTopPosition] = useState("-200px");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setTopPosition("0");
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  const h1Style = {
    overflow: "hidden",
    position: "relative",
    // top: topPosition,
    // transition: "top 1.5s ease-in-out",
    // Adjust the transition duration and timing function as needed
  };

  return (
    <>
      <div className="splash-screen">
        <div className="typewriter">
          <h1 className="" style={{ overflow: "hidden" }}>
            <a class="flip-animate fw-light d-flex" target="_blank">
              <span data-aos="fade-up">Welcome</span>
              <span className="ms-2" data-aos-delay="500" data-aos="fade-up">
                to
              </span>
              <span
                data-aos-delay="1000"
                data-aos="fade-up"
                className="ms-2 fw-bolder"
                data-hover="Flip"
                style={h1Style}
              >
                |
              </span>

              <span
                data-aos-delay="1500"
                data-aos="fade-up"
                className="ms-2 fw-bolder"
                data-hover="Flip"
                style={h1Style}
              >
                Extended
              </span>

              <span
                data-aos-delay="2000"
                data-aos="fade-up"
                className="ms-2 fw-bolder"
                data-hover="Flip"
                style={h1Style}
              >
                Trust
              </span>
            </a>
          </h1>
        </div>

        <div class="loader">
          <svg
            class="car"
            width="102"
            height="40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              transform="translate(2 1)"
              stroke="#000"
              // stroke="#4a6543"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                class="car__body"
                d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"
                stroke-width="2"
              />
              <ellipse
                class="car__wheel--left"
                stroke-width="3.2"
                fill="#fff"
                cx="83.493"
                cy="30.25"
                rx="6.922"
                ry="6.808"
              />
              <ellipse
                class="car__wheel--right"
                stroke-width="3.2"
                fill="#fff"
                cx="46.511"
                cy="30.25"
                rx="6.922"
                ry="6.808"
              />
              <path
                class="car__line car__line--top"
                d="M22.5 16.5H2.475"
                stroke-width="3"
              />
              <path
                class="car__line car__line--middle"
                d="M20.5 23.5H.4755"
                stroke-width="3"
              />
              <path
                class="car__line car__line--bottom"
                d="M25.5 9.5h-19"
                stroke-width="3"
              />
            </g>
          </svg>
        </div>

        <div className="line-breaker"></div>
      </div>
    </>
  );
};

export default Splash;
