import React from "react";

const Page_header = (props) => {
  return (
    <div
      className={
        "ltn__breadcrumb-area text-left bg-image other-pages " +
        props.CustomClass
      }
      style={{ backgroundImage: `url('${props.imgsrc}')` }}
    >
      <div className="contain">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__breadcrumb-inner">
              <h1 className="page-title" style={{ lineHeight: "100%" }}>
                {props.headertitle}
              </h1>
              <div className="ltn__breadcrumb-list">
                <ul>
                  <li>
                    <a href="/home">
                      <span className="ltn__secondary-color">
                        <i className="fas fa-home" />
                      </span>{" "}
                      Home
                    </a>
                  </li>
                  <li>{props.headertitle}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ltn__breadcrumb-area-layer"></div>
      <img
        src="/assets/img/selfmade/Images/smoke.png"
        alt="..."
        className="smoke-layer"
      />
    </div>
  );
};

export default Page_header;
