import React from "react";
import "./products.css";
import Page_header from "../page-header";
const Products = () => {
  return (
    <section className="products-sec">
      <Page_header
        headertitle="Products"
        imgsrc="/assets/img/selfmade/Images/Product.png"
      />
      <div className="privacyInfo-center">
        <h2>
          Extended Trust <span>Products</span>
        </h2>
        <h4>
          {/* Path to Worry Free Driving */}
          OIL CHANGE PROGRAM
        </h4>
        <p>
          Explore our exclusive five year program, designed to provide peace of
          mind and hassle free maintenance for your vehicle. Also Includes 3
          year tire storage program with the option to upgrade to lifetime oil
          changes and tire storage services
        </p>
        <h4>Trust Care</h4>
        <p>
          Uncover the power of knowledge with "Trust Care". Our diagnostic
          coverage goes beyond the surface, providing a thorough analysis of
          your vehicle's health.
        </p>
        <h4>Trust Shield</h4>
        <p>
          Safeguard your view of the road with "Trust Shield." This program
          covers windshield crack repairs, window replacements, and even sunroof
          services. Drive fearlessly, knowing that your vehicle's glass is
          protected. Trust Shield ensures clarity, safety, and peace of mind for
          every journey.
        </p>
        <h4>Tire and Rim</h4>
        <p>
          Elevate your driving experience with our "Tire and Rim" program. Enjoy
          the freedom of the road without worrying about tire damage or rim
          scuffs. This program offers comprehensive coverage, providing repairs
          or replacements for your tires and rims. Drive with confidence,
          knowing that we've got your wheels covered.
        </p>
        <h4>Key Fob Replacement</h4>
        <p>
          Never be stranded again with our "Key Fob Replacement" program. Losing
          or damaging your key fob can be stressful, but with our coverage, a
          replacement is just a call away. Experience the convenience of
          seamless access to your vehicle, ensuring you're always in control.
        </p>
        <h4>Brakes and Rotors</h4>
        <p>
          Prioritize safety with our "Brakes and Rotors" program. Comprehensive
          coverage for your braking system ensures optimal performance and
          responsiveness. From brake pad replacements to rotor maintenance,
          trust us to keep your vehicle braking smoothly, providing you with
          peace of mind on every journey.
        </p>
        <h4>
          Appearance
          <span>(Dents, Scratches, Rock Chips, and Windshield Chips)</span>
        </h4>
        <p>
          Preserve the beauty of your vehicle with our "Appearance" program. Say
          goodbye to dents, scratches, rock chips, and windshield chips. Our
          comprehensive coverage ensures that your vehicle stays in pristine
          condition. Trust us to restore and maintain the appearance of your
          car, allowing you to drive with confidence and pride.
        </p>

        <h4>Trust Guard </h4>
        <p>
          Our Trust Guard provides an advanced layer of protection for your
          vehicle's paint and shine, Ensuring it stays in showroom condition.
        </p>

        <h4>Anti-Theft</h4>
        <p>
          With Anti-Theft, you can enjoy peace of mind knowing that your vehicle
          is protected and that you have financial support in case of theft.
        </p>
      </div>
    </section>
  );
};

export default Products;
