import React, { Component, useState, useEffect, useRef } from "react";
import Hamburger from "hamburger-react";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const [isAboutOpen, setAboutOpen] = useState(false);
  const menuRef = useRef();
  const toggleRef = useRef();
  const toggleAbout = () => {
    setAboutOpen(!isAboutOpen);
  };
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      console.log("Here");
      if (toggleRef.current.contains(event.target)) {
        console.log("inside hamburger");
      } else {
        setOpen(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const location = useLocation();

  const shouldHide = ["/log"].includes(location.pathname);
  useEffect(() => {
    if (isOpen == false) {
      setAboutOpen(false);
    }
  }, [isOpen]);
  return (
    <>
      <div className={` ${shouldHide ? "d-none" : ""}`}>
        <header
          className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-2  mx-auhref d-none d-lg-block"
          style={{ width: "910px" }}
        >
          <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black text-black">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="site-logo-wrap">
                    <div
                      className="site-logo go-hrefp"
                      style={{ width: "140px", height: "50px" }}
                    >
                      <a href="/home">
                        <img
                          src="/assets/img/brand-logo/logoblack.svg"
                          alt="Logo"
                          className="logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col header-menu-column menu-color-black ">
                  <div className="header-menu d-none d-md-block go-hrefp">
                    <nav>
                      <div className="ltn__main-menu">
                        <ul>
                          <li>
                            <a href="/home">Home</a>
                          </li>
                          <li>
                            <div class="dropdown ">
                              <button class="dropbtn ">
                                About{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                >
                                  <g transform="rotate(180 12 12)">
                                    <g fill="none" fill-rule="evenodd">
                                      <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                      <path
                                        fill="currentColor"
                                        d="M11.293 8.293a1 1 0 0 1 1.414 0l5.657 5.657a1 1 0 0 1-1.414 1.414L12 10.414l-4.95 4.95a1 1 0 0 1-1.414-1.414z"
                                      />
                                    </g>
                                  </g>
                                </svg>{" "}
                              </button>
                              <div class="dropdown-content">
                                <a href="/about">About Us</a>
                                <a href="/meet-the-team">Meet the Team</a>
                                {/* <a href="/blog">Blog</a> */}
                                {/* <a href="/reviews">Taricks Notes</a> */}
                              </div>
                            </div>
                          </li>

                          <li>
                            <a href="/repairs">Repairs</a>
                          </li>

                          <li>
                            <a href="/contact">Contact</a>
                          </li>

                          <li className="special-link ms-auhref">
                            <a href="https://dashboard.extendedtrust.ca/">
                              DEALER LOGIN
                            </a>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="mb-nav w-100 d-block d-lg-none">
          <div className="mobile-nav m-auto">
            <div className="navlogo-sec">
              <a href="/home">
                <img src="/assets/img/brand-logo/logoblack.svg" alt="..." />
              </a>
              <div ref={toggleRef}>
                <Hamburger toggled={isOpen} toggle={setOpen} />
              </div>
            </div>
            {isOpen && (
              <div className="mobilenav-content" ref={menuRef}>
                <ul>
                  <li>
                    <a href="/home">Home</a>
                  </li>
                  <li>
                    <a onClick={toggleAbout}>
                      About{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g transform="rotate(180 12 12)">
                          <g fill="none" fill-rule="evenodd">
                            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                            <path
                              fill="currentColor"
                              d="M11.293 8.293a1 1 0 0 1 1.414 0l5.657 5.657a1 1 0 0 1-1.414 1.414L12 10.414l-4.95 4.95a1 1 0 0 1-1.414-1.414z"
                            />
                          </g>
                        </g>
                      </svg>
                    </a>
                    {isAboutOpen && (
                      <div className="mobile-about">
                        <ul>
                          <li>
                            <a href="/about">About Us</a>
                          </li>
                          <li>
                            <a href="/meet-the-team">Meet the Team</a>
                          </li>
                          {/* <li>
                            <a href="/blog">Blog</a>
                          </li> */}
                          {/* <li>
                          <a href="/reviews">Taricks Notes</a>
                        </li> */}
                        </ul>
                      </div>
                    )}
                  </li>
                  <li>
                    <a href="/repairs">Repairs</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                  <li className="moblibebtn-li">
                    <a
                      href="https://dashboard.extendedtrust.ca/"
                      className="mobile-btn"
                    >
                      DEALER LOGIN
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
