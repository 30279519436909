import React from "react";
import { Link } from "react-router-dom";

const Services = (props) => {
  return (
    <div>
      <div className={props.customClass}>
        <div className="contain">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Our Services
                </h6>
                <h1 className="section-title">
                  Our <span>Primary Concerns</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row ltn__custom-gutter--- justify-content-center go-top">
            <div
              className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1"
              style={{ borderRadius: "15px" }}
            >
              <div className="ltn__feature-icon">
                <img src="/assets/img/selfmade/icon/02.png" alt="#" />
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="/">Protect Your Vehicle</Link>
                </h3>
                <p>
                  Safeguard your vehicle with our reliable protection. Drive
                  worry free knowing your investment is shielded. Choose peace
                  of mind today!
                </p>
                <Link className="ltn__service-btn" to="/">
                  Get Protected Now
                  {/* <i className="flaticon-right-arrow" /> */}
                </Link>
              </div>
            </div>

            <div
              className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 "
              style={{ borderRadius: "15px" }}
            >
              <div className="ltn__feature-icon">
                <img src="/assets/img/selfmade/icon/03.png" alt="#" />
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="/">Buy Vehicle Protection</Link>
                </h3>
                <p>
                  Explore robust shields for your ride. With a wide selection
                  available, find the perfect shield for your vehicle.
                </p>
                <Link className="ltn__service-btn" to="/">
                  Get Protected Now
                  {/* <i className="flaticon-right-arrow" /> */}
                </Link>
              </div>
            </div>

            <div
              className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1"
              style={{ borderRadius: "15px" }}
            >
              <div className="ltn__feature-icon">
                <img src="/assets/img/selfmade/icon/01.png" alt="#" />
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="/">Rent Vehicle Security</Link>
                </h3>
                <p>
                  Secure your journey with temporary protection solutions. Rent
                  tailored security features for your vehicle's needs. Drive
                  with confidence, hassle free.
                </p>
                <Link className="ltn__service-btn" to="/">
                  Get Protected Now
                  {/* <i className="flaticon-right-arrow" /> */}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
