import React from "react";

const Breaker = () => {
  return (
    <div>
      <div className="ltn__video-popup-area ltn__video-popup-margin--- mainvideo-sec fixvid-sec">
        <div
          className="ltn__video-bg-img ltn__video-popup-height-600--- bg-overlay-black-30 bg-image bg-fixed ltn__animation-pulse1 bbg"
          data-bs-bg="\assets\img\selfmade\Images\main-backcopy.jpg"
        ></div>
      </div>
    </div>
  );
};

export default Breaker;
