import React, { useEffect } from "react";
import CopyRight from "./copyRight";
import { useLocation } from "react-router-dom";
const Footer = () => {
  const location = useLocation();
  const shouldHide = ["/"].includes(location.pathname);
  return (
    <>
      <footer className={`ltn__footer-area `}>
        <div
          className="footer-top-area  section-bg-2 "
          style={{ borderBottom: "1px solid white" }}
        >
          <div className="container-fluid">
            <div className="footertop-sec">
              <div className="logo-side">
                <div className="footer-logo">
                  <div className="site-logo">
                    <a href="/home">
                      <img
                        src="/assets/img/brand-logo/logowhite.svg"
                        alt="Logo"
                        className="logo"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="heading-side">
                <h1 className="footer-head">
                  Explore Canada's Top Rated Vehicle Protection Plans
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Company</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <a href="/home">Home</a>
                      </li>
                      <li>
                        <a href="/about">About Us</a>
                      </li>
                      <li>
                        <a href="/repairs">Repairs</a>
                      </li>
                      <li>
                        <a href="/become-a-dealer">Become a Dealer</a>
                      </li>

                      <li>
                        <a href="/contact">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Resources</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      {/* <li>
                        <a href="/blog">Blog</a>
                      </li> */}
                      {/* <li>
                        <a href="/faqs">FAQ</a>
                      </li> */}
                      <li>
                        <a href="/meet-the-team">Meet the Team</a>
                      </li>
                      {/* <li>
                        <a href="/reviews">Taricks Notes</a>
                      </li> */}
                      <li>
                        <a href="/products">Products</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Contact</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>Unit 200 14964 121A Ave NW Edmonton Alberta</li>
                      <li>
                        <a href="tel:1-888-418-1711">1 888 418 1711</a>
                      </li>
                      <li>
                        <a
                          href="mailto:support@extendedtrust.ca"
                          target="_blank"
                          rel="noreferrer"
                        >
                          support@extendedtrust.ca
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CopyRight />
      </footer>
    </>
  );
};

export default Footer;
