import React from "react";
import "./contactInfo.css";

const ContactInfo = () => {
  return (
    <div>
      <div className="contactInfo-container">
        <div className="contactInfo-center">
          <h2>
            CONTACT <span>INFORMATION</span>
          </h2>
          <p>
            Extended Trust Client Care Program is dedicated to covering your
            absolute satisfaction every moment
          </p>
          <h3>HEAD OFFICE</h3>
          <div className="headOffice-container">
            <div>
              TOLL FREE
              <span>1 888 418 1711</span>
            </div>

            <div>
              EMAIL
              <span>support@extendedtrust.ca</span>
            </div>
          </div>
        </div>
      </div>
      {/* Location Section */}
      <div className="contact-container-flex">
        <div className="left-shape-container-contact">
          <div class="shapecontact">
            <div class="inner-shape-contact">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2369.0948226665564!2d-113.58139728737828!3d53.57392427224085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a021572a588ced%3A0xd7267e8b50e8b70e!2s14904%20121a%20Ave%20NW%20%23200%2C%20Edmonton%2C%20AB%20T5V%201A3%2C%20Canada!5e0!3m2!1sen!2s!4v1703568747829!5m2!1sen!2s"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="w-100 h-100 rounded"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="right-shape-container-contact">
          <div className="contact-address-head-section">
            <h5>Extended Trust</h5>
            <a href="#">Suite #200 14964 121A Ave NW Edmonton Alberta</a>
          </div>

          <div class="panel-text-wrapper">
            <div class="">
              <div class="">
                <div className="close-div d-flex align-items-center">
                  <p class="open-text mb-s0">Our Business Hours</p>
                  {/* <h5 class="fancy-text">HOURS</h5> */}
                </div>

                <table class="hours-table">
                  <tr>
                    <th>Monday:</th>
                    <td>9:00am - 5:00pm</td>
                  </tr>
                  <tr>
                    <th>Tuesday:</th>
                    <td>9:00am - 5:00pm</td>
                  </tr>
                  <tr>
                    <th>Wednesday:</th>
                    <td>9:00am - 5:00pm</td>
                  </tr>
                  <tr class="today">
                    <th>Thursday:</th>
                    <td>9:00am - 5:00pm</td>
                  </tr>
                  <tr>
                    <th>Friday:</th>
                    <td>9:00am - 5:00pm</td>
                  </tr>
                  <tr>
                    <th>Saturday:</th>
                    <td>Closed</td>
                  </tr>
                  <tr>
                    <th>Sunday:</th>
                    <td>Closed</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Location Section */}
    </div>
  );
};

export default ContactInfo;
