import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Testimonials = () => {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: "0px",
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <div>{">"}</div>,
    prevArrow: <div>{"<"}</div>,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 787,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <div
      className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115   position-relative d-none"
      style={{
        backgroundImage: "url('/assets/img/selfmade/Images/greentest.jpg')",
        paddingBottom: "140px",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h1 className="section-title text-white">Listen to Clients</h1>
            </div>
          </div>
        </div>
        <div className="row ltn__testimonial-slider-5-active slick-arrow-1">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="col-xl-4 col-md-6">
                <div className="ltn__testimonial-item ltn__testimonial-item-7">
                  <div className="ltn__testimoni-info">
                    <p>
                      <i className="flaticon-left-quote-1" />
                      {testimonial.quote}
                    </p>
                    <div className="ltn__testimoni-info-inner">
                      <div className="ltn__testimoni-img">
                        <img src="/assets/img/selfmade/icon/user.png" alt="#" />
                      </div>
                      <div className="ltn__testimoni-name-designation">
                        <h5>{testimonial.author}</h5>
                        {/* <label>{testimonial.designation}</label> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <img
        src="/assets/img/selfmade/Images/smoke.png"
        alt=""
        className="smoke-layer"
      />
    </div>
  );
};

const testimonials = [
  {
    quote:
      "Extended Trust has been a lifesaver for my car! Their vehicle protection plans are unmatched in Canada, and I can't imagine owning a car without their comprehensive coverage.",
    author: "Benjamin J",
    designation: "Selling Agents",
  },
  {
    quote:
      "I've been a loyal Extended Trust customer for years, and they have never let me down. Their commitment to vehicle safety and protection is truly commendable.",
    author: "Sofia A",
    designation: "Selling Agents",
  },
  {
    quote:
      "Choosing Extended Trust for my car's protection was one of the best decisions I've made. Their programs are tailor-made for the Canadian market and offer unbeatable peace of mind.",
    author: "Liam E",
    designation: "Selling Agents",
  },
  {
    quote:
      "Extended Trust delivers on its promise of worry free wear care. My car has never looked better, thanks to their top-tier protection programs.",
    author: "Brian W",
    designation: "Selling Agents",
  },
  {
    quote:
      "As a proud Extended Trust customer, I can say that their vehicle protection plans are worth every penny. I drive with confidence knowing my car is in safe hands.",
    author: "Jessica A",
    designation: "Selling Agents",
  },
  {
    quote:
      "Extended Trust goes above and beyond to shield your car from life's mishaps. Their dedication to vehicle safety is unmatched in Canada.",
    author: "Kevin T",
    designation: "Selling Agents",
  },
  {
    quote:
      "I've recommended Extended Trust to all my friends and family. Their vehicle protection plans are a game changer for car owners in Canada.",
    author: "Laura S",
    designation: "Selling Agents",
  },
  {
    quote:
      "Extended Trust is the go to choice for anyone looking to protect their car in Canada. Their programs are comprehensive, and their service is impeccable.",
    author: "Daniel M",
    designation: "Selling Agents",
  },
  {
    quote:
      "I'm impressed with Extended Trust's commitment to my car's safety and intelligence. Their protection plans have exceeded my expectations.",
    author: "Olivia W",
    designation: "Selling Agents",
  },
  {
    quote:
      "Extended Trust has made owning a car in Canada stress free. I can't imagine driving without their excellent vehicle protection.",
    author: "Stephen W",
    designation: "Selling Agents",
  },
  {
    quote:
      "My experience with Extended Trust has been nothing short of outstanding. Their team's attention to detail and dedication to customer satisfaction are truly remarkable.",
    author: "Sarah D",
    designation: "Selling Agents",
  },
  {
    quote:
      "Extended Trust's vehicle protection plans are a lifesaver for anyone who values their car. I sleep better at night knowing my vehicle is in their capable hands.",
    author: "Mark T",
    designation: "Selling Agents",
  },
  {
    quote:
      "I've been a satisfied Extended Trust customer for over a decade. Their consistent quality and commitment to car protection make them the best in the business.",
    author: "Jennifer L",
    designation: "Selling Agents",
  },
  {
    quote:
      "Extended Trust has earned my trust with their exceptional service. Their vehicle protection plans have saved me from unexpected expenses and headaches.",
    author: "Christopher C",
    designation: "Selling Agents",
  },
  {
    quote:
      "Choosing Extended Trust was a no brainer for me. Their vehicle protection plans are comprehensive, affordable, and provide the peace of mind every car owner needs.",
    author: "Patricia M",
    designation: "Selling Agents",
  },
  {
    quote:
      "Extended Trust's dedication to keeping my car in top shape has been a game changer. Their attention to detail and commitment to excellence set them apart.",
    author: "Thomas W",
    designation: "Selling Agents",
  },
  {
    quote:
      "I've recommended Extended Trust to all my colleagues, and they have all thanked me for introducing them to such a reliable and trustworthy car protection provider.",
    author: "Rebecca A",
    designation: "Selling Agents",
  },
  {
    quote:
      "Extended Trust is the gold standard in vehicle protection. Their plans are flexible, and their customer service is top notch. I wouldn't trust anyone else with my car.",
    author: "Matthew T",
    designation: "Selling Agents",
  },
  {
    quote:
      "I'm grateful to Extended Trust for their unwavering support in keeping my car safe and reliable. Their dedication to vehicle protection is unmatched, and I'm a loyal customer for life.",
    author: "Elizabeth C",
    designation: "Selling Agents",
  },
];

export default Testimonials;
