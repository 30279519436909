import React from "react";
import "./becomedealer.css";
import { useNavigate } from "react-router-dom";
const Becomedealer = (props) => {
  return (
    <section className="becomedealer-sec">
      <div className="inner">
        <h1 className="section-title head">BECOME A DEALER</h1>
        <p>{props.dealerpara}</p>
        <div className="btn-wrapper animated go-top">
          <a href="/contact" className="theme-btn-1 btn btn-effect-1">
            CONTACT US
          </a>
        </div>
      </div>
      <div className="layer"></div>
      <img
        src="/assets/img/selfmade/Images/smoke.png"
        alt="..."
        className="smoke-layer"
      />
    </section>
  );
};

export default Becomedealer;
