import { Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./page/home";
import Navbar from "./components/shared/navbar";
import Footer from "./components/shared/footer";
import Team from "./components/team/team";
import Reviews from "./components/review/reviews";
import Products from "./components/products/products";
import Faqs from "./components/faq/faqs";
import Blogsec from "./components/blogs/blog/blog";
import Blog1 from "./components/blogs/blog1/blog1";
import Blog2 from "./components/blogs/blog2/blog2";
import Blog3 from "./components/blogs/blog3/blog3";
import Blog4 from "./components/blogs/blog4/blog4";
import Blog5 from "./components/blogs/blog5/blog5";
import Blog6 from "./components/blogs/blog6/blog6";
import Becomedealerpage from "./page/becomedealerpage";
import Privacypolicy from "./components/privacy/privacypolicy";
import Terms from "./components/termsandconditions/terms";
import Contact from "./page/contact";
import Repairs from "./components/repair/repairs";
import About from "./page/about";
import Splash from "./page/splash";
import CookieConsent from "react-cookie-consent";
import Home2 from "./page/home2";
function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Navbar />
      <Routes>
        {/* <Route path="/" element={<Splash />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home2 />} />
        <Route path="/about" element={<About />} />
        <Route path="/repairs" element={<Repairs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/become-a-dealer" element={<Becomedealerpage />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/terms&conditions" element={<Terms />} />
        {/* <Route path="/blog" element={<Blogsec />} />
        <Route path="/blog1" element={<Blog1 />} />
        <Route path="/blog2" element={<Blog2 />} />
        <Route path="/blog3" element={<Blog3 />} />
        <Route path="/blog4" element={<Blog4 />} />
        <Route path="/blog5" element={<Blog5 />} />
        <Route path="/blog6" element={<Blog6 />} /> */}
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/products" element={<Products />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/meet-the-team" element={<Team />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
