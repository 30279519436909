import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./contactform.css";
import { Modal, Toast } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
import { Icon } from "@iconify/react";

const Contactform = () => {
  const form = useRef();
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [email, setEmail] = useState("");
  const [showmodal, setShowmodal] = useState(false);

  const handlePhoneNumberChange = (value) => {
    const formattedValue = value.startsWith("+1") ? value : `+1 ${value}`;
    setInputValue(formattedValue);

    // Phone number pattern for US and Canadian numbers
    const phonePattern = /^(\+1\s?)?(\()?\d{3}(\))?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    const isValid = phonePattern.test(formattedValue);
    setIsValidPhoneNumber(isValid);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    handlePhoneNumberChange(value);
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_hg4zv6k",
        "template_6oo898j",
        form.current,
        "2xPyGSRZ885cetaDY"
      )
      .then(
        (result) => {
          console.log(result.text);
          // Clear the form fields after a successful submission
          // Swal.fire({
          //   title: "Thank You!",
          //   text: "Thank you for contacting Extended Trust.",
          //   icon: "success",
          //   confirmButtonColor: "#4A6543",
          // });
          // toast.success("Thank you for contacting Extended Trust");
          form.current.reset();
          setShowmodal(true);
          setInputValue("");
          setEmail("");
          // Show the success toast
          // Hide the success toast after a few seconds
          // setTimeout(() => {
          //   setShowSuccessToast(false);
          // }, 5000);
        },
        (error) => {
          console.log(error.text);
          // Show the error toast
          setShowErrorToast(true);
          // Hide the error toast after a few seconds
          setTimeout(() => {
            setShowErrorToast(false);
          }, 5000);
        }
      );
  };
  const createAutoReply = async (event, userEmail) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `https://extended-trust-nodejs-d43e1844c232.herokuapp.com/admin/auto-reply`,
        { email: userEmail }
      );

      console.log(response.data);
      // if (response.data) {
      //   toast.success("Staff Added Successfully");
      // }
    } catch (error) {
      // if (error.response) {
      toast.error(error);
      // }
      //   toast.error(error);
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <Modal show={showmodal} className="confirmmodal">
        <Modal.Body>
          <Icon
            icon="mdi:tick-circle"
            width="60"
            height="60"
            style={{ color: "#4A6543" }}
          />
          <h4>Thank You!</h4>
          <p>Your message has been submitted successfully.</p>
          <button onClick={() => setShowmodal(false)}>OK</button>
        </Modal.Body>
      </Modal>
      <div className="contactform-send d-flex pb-60 mt-5">
        <div className="contactform-left-container">
          <div className="contactform-SendmsgText">
            <h2>
              Send Us a {""}
              <span>Message</span>
            </h2>
          </div>
          <p>
            Connect with ease drop us a message, and let's start resolving your
            queries promptly
          </p>

          <form
            id="contact-form"
            name="contact-form"
            onSubmit={sendEmail}
            data-recaptcha=""
            ref={form}
          >
            <div class="form-field">
              <label class="label" for="msg-name">
                Full Name <span class="required">*</span>
              </label>
              <input
                type="text"
                name="name"
                class="input jsvalidate"
                id="msg-name"
                required
              />
            </div>
            <div class="form-field">
              <label class="label" for="msg-email">
                Email Address <span class="required">*</span>
              </label>
              <input
                type="email"
                name="email"
                id="msg-email"
                class="input jsvalidate"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  createAutoReply(e, e.target.value);
                }}
                required
              />
            </div>
            <div class="form-field">
              <label class="label" for="msg-phone">
                Phone Number <span class="required">*</span>
              </label>
              <input
                className="input jsvalidate"
                type="tel" // Use type 'tel' for phone numbers
                name="phone"
                id="msg-phone"
                value={inputValue}
                onChange={handleChange}
                maxLength={13}
                required
              />
              {/* <input
                type="number"
                name="phone"
                id="msg-phone"
                class="input jsvalidate "
                required
              /> */}
            </div>
            <div class="form-field">
              <label class="label" for="msg-comments">
                Message <span class="required">*</span>
              </label>
              <textarea
                name="message"
                id="msg-comments"
                class="textarea jsvalidate"
                required
              ></textarea>
            </div>
            <div class="form-buttons right">
              <button
                type="submit"
                name="submitform"
                class="button submit theme-btn-1 btn btn-effect-1"
                // onClick={(e) => createAutoReply(e, email)}
              >
                <span>SEND MESSAGE</span>
              </button>
            </div>
            {/* <div
              class="recaptcha-modal hidden-modal"
              title="Verify You&rsquo;re Not a Robot"
            >
              <div class="recaptcha-wrapper">
                <div
                  id="recaptcha-contact-form"
                  class="g-recaptcha"
                  data-sitekey="6Ld3_UcUAAAAAERxamTGAnRHI-VXvmJJCkfG2KWg"
                ></div>
              </div>
            </div>
            <input
              type="hidden"
              name="xid"
              value="d7e90aac166553141e80b3e173c30b6a"
            /> */}
            {/* <input type="hidden" name="g-recaptcha-response" /> */}
          </form>
          {/* <Toast
            show={showSuccessToast}
            onClose={() => setShowSuccessToast(false)}
            className="success-toast"
            delay={5000}
            autohide
          >
            <Toast.Body>Message sent successfully!</Toast.Body>
          </Toast>

          <Toast
            show={showErrorToast}
            onClose={() => setShowErrorToast(false)}
            className="error-toast"
            delay={5000}
            autohide
          >
            <Toast.Body>Message sending failed. Please try again.</Toast.Body>
          </Toast> */}
        </div>
        <div className="contactform-right-container">
          <img
            src="/assets/img/selfmade/Images/contact-side.png"
            alt=""
            style={{ maxHeight: "900px", height: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Contactform;
