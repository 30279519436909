import React from "react";
import "./chooseus.css";
const Chooseus = (props) => {
  return (
    <section className={`values-sec ${props.customClass}`}>
      <h1 className="section-title">
        WHY CHOOSE <br />
        <span>EXTENDED TRUST</span>
      </h1>
      <div className="values-content">
        <div className="second">
          <img src="/assets/img/selfmade/icon/dealer/call.jpg" alt="" />
          <h2>Extended Care, Extended Value</h2>
        </div>
        <div className="second">
          <img src="/assets/img/selfmade/icon/dealer/search.jpg" alt="" />
          <h2>Transparent and Fair</h2>
        </div>
        <div className="second">
          <img src="/assets/img/selfmade/icon/dealer/innovation.jpg" alt="" />
          <h2>Innovation in Protection</h2>
        </div>
        <div className="second">
          <img src="/assets/img/selfmade/icon/dealer/expert.jpg" alt="" />
          <h2>Proven Expertise</h2>
        </div>
      </div>
    </section>
  );
};

export default Chooseus;
