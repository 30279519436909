import { useEffect, useRef } from 'react';
import './fashion-slider/fashion-slider.scss';
import createFashionSlider from './fashion-slider/fashion-slider';

function Slider() {
  const sliderElRef = useRef(null);
  useEffect(() => {
    createFashionSlider(sliderElRef.current);
  }, []);
  return (
    <div className='fashion-slider' ref={sliderElRef}>
      <div className='swiper'>
        <div className='swiper-wrapper'>
          {data.map((items, index) => {
            return (
              <div className='swiper-slide' data-slide-bg-color='#4a6543'>
                <div
                  className='fashion-slider-title'
                  // data-swiper-parallax="-130%"
                >
                  <div className='fashion-slider-title-text'>
                    {items.header} <br />
                    <span> {items.step}</span>
                  </div>
                </div>

                <div className='fashion-slider-scale'>
                  <img src={items.img} />
                </div>
              </div>
            );
          })}

          {/* <div className="swiper-slide" data-slide-bg-color="#4a6543">
            <div className="fashion-slider-title" data-swiper-parallax="-130%">
              <div className="fashion-slider-title-text">Slider 2</div>
            </div>

            <div className="fashion-slider-scale">
              <img src="https://s3.us-west-2.amazonaws.com/extendedtrustcdn/slider1.jpg" />
            </div>
          </div>

          <div className="swiper-slide" data-slide-bg-color="#4a6543">
            <div className="fashion-slider-title" data-swiper-parallax="-130%">
              <div className="fashion-slider-title-text">Slider 3</div>
            </div>

            <div className="fashion-slider-scale">
              <img src="https://s3.us-west-2.amazonaws.com/extendedtrustcdn/slider2.jpg" />
            </div>
          </div> */}
        </div>

        <div className='fashion-slider-button-prev fashion-slider-button'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 350 160 90'>
            <g className='fashion-slider-svg-wrap'>
              <g className='fashion-slider-svg-circle-wrap'>
                <circle cx='42' cy='42' r='40'></circle>
              </g>
              <path
                className='fashion-slider-svg-arrow'
                d='M.983,6.929,4.447,3.464.983,0,0,.983,2.482,3.464,0,5.946Z'
              ></path>
              <path className='fashion-slider-svg-line' d='M80,0H0'></path>
            </g>
          </svg>
        </div>

        <div className='fashion-slider-button-next fashion-slider-button'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 350 160 90'>
            <g className='fashion-slider-svg-wrap'>
              <g className='fashion-slider-svg-circle-wrap'>
                <circle cx='42' cy='42' r='40'></circle>
              </g>
              <path
                className='fashion-slider-svg-arrow'
                d='M.983,6.929,4.447,3.464.983,0,0,.983,2.482,3.464,0,5.946Z'
              ></path>
              <path className='fashion-slider-svg-line' d='M80,0H0'></path>
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
}

const data = [
  {
    header: 'Extended Program ',
    step: 'Step 1',
    img: 'https://s3.us-west-2.amazonaws.com/extendedtrustcdn/slider1.jpg',
  },
  {
    header: '  Something Happens  ',
    step: 'Step 2',
    img: 'https://s3.us-west-2.amazonaws.com/extendedtrustcdn/slider2.jpg',
  },
  {
    header: 'Get It Repaired ',
    step: 'Step 3',
    img: 'https://s3.us-west-2.amazonaws.com/extendedtrustcdn/slider3.jpg',
  },

  {
    header: 'Extended Program ',
    step: 'Step 1',
    img: 'https://s3.us-west-2.amazonaws.com/extendedtrustcdn/slider1.jpg',
  },
  {
    header: '  Something Happens  ',
    step: 'Step 2',
    img: 'https://s3.us-west-2.amazonaws.com/extendedtrustcdn/slider2.jpg',
  },
  {
    header: 'Get It Repaired ',
    step: 'Step 3',
    img: 'https://s3.us-west-2.amazonaws.com/extendedtrustcdn/slider3.jpg',
  },

  {
    header: 'Extended Program ',
    step: 'Step 1',
    img: 'https://s3.us-west-2.amazonaws.com/extendedtrustcdn/slider1.jpg',
  },
  {
    header: '  Something Happens  ',
    step: 'Step 2',
    img: 'https://s3.us-west-2.amazonaws.com/extendedtrustcdn/slider2.jpg',
  },
  {
    header: 'Get It Repaired ',
    step: 'Step 3',
    img: 'https://s3.us-west-2.amazonaws.com/extendedtrustcdn/slider3.jpg',
  },
];

export default Slider;
