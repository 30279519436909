import React from "react";

const Report = ({
  title,
  spantitle,
  tagline,
  downTagLine,
  btnTxt,
  para,
  customclass,
}) => {
  return (
    <div
      className={`ltn__about-us-area pt-120 pb-90 report-main position-relative ${customclass} home-secondabout`}
    >
      <div className="contain">
        <div className="report-sec">
          <div className="report-content">
            <div className="section-title-area ltn__section-title-2--- mb-30">
              <h1 className="section-title" style={{ lineHeight: "110%" }}>
                {title} {spantitle}
              </h1>
              <p>{tagline}</p>
            </div>
            <div className="ltn__callout">
              <p>{para}</p>
            </div>
            <div
              className="section-title-area"
              style={{ marginBottom: "20px" }}
            >
              <p>{downTagLine}</p>
            </div>
            <div
              className="btn-wrapper animated go-top"
              style={{ marginTop: "0px" }}
            >
              <a
                href="/repairs"
                className="theme-btn-1 btn btn-effect-1"
                style={{ fontSize: "20px" }}
              >
                {btnTxt}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-img">
        <img
          src="/assets/img/selfmade/Images/reportcopy.jpg"
          alt=""
          className="w-100 h-100 object-cover"
        />
      </div>
    </div>
  );
};

export default Report;
