import React from "react";
import "./repairabout.css";
const Repairabout = () => {
  return (
    <div className="repair-about">
      <div className="ra-content">
        <h1 className="section-title ">
          Let's team up to turn this <span>setback into a comeback!</span>
        </h1>
        <p>
          Embrace resilience with our repair services where setbacks transform
          into comebacks. Our adept technicians pair cutting edge technology
          with unparalleled skills to not just repair, but improve your vehicle.
          Trust us to revitalize your car and exceed expectations, turning
          obstacles into exceed on the road ahead.
        </p>
      </div>
    </div>
  );
};

export default Repairabout;
